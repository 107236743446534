<template>
  <div>
     <!-- v-model="active" active-color="#000" @change="onChange" -->
    <van-tabbar route v-model="active" active-color="#000" @change="onChange" >
      <!-- badge="3" -->
      <van-tabbar-item replace to="../homePage/index">
        <span>首页</span>
        <template #icon="props">
          <img :src="active == 0 ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="../classify/index" >
        <span>分类</span>
        <template #icon="props">
          <img :src="active == 1 ? icon1.active : icon1.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="../shoppings/index">
        <span>购物车</span>
        <template #icon="props">
          <img :src="active == 2 ? icon2.active : icon2.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="../my/index">
        <span>我的</span>
        <template #icon="props">
          <img :src="active == 3 ? icon3.active : icon3.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "CodeReact",

  data() {
    return {
       active: 0,
        icon: {
          active: require('@/assets/image/tab1.png'),
          inactive: require('@/assets/image/tab01.png'),
        },
        icon1: {
          active: require('@/assets/image/tab2.png'),
          inactive: require('@/assets/image/tab02.png'),
        },
        icon2: {
          active: require('@/assets/image/tab3.png'),
          inactive: require('@/assets/image/tab03.png'),
        },
        icon3: {
          active: require('@/assets/image/tab4.png'),
          inactive: require('@/assets/image/tab04.png'),
        },
        appUrl: ''
    };
  },

  mounted() {
    if (window.location.href.indexOf('shoppings/index') !== -1) {
      this.active = 2
    } else if (window.location.href.indexOf('my/index') !== -1) {
      this.active = 3
    } else if (window.location.href.indexOf('classify/index') !== -1) {
      this.active = 1
    } else {
      this.active = 0
    }
  },

  methods: {
    onChange(index) {
      this.active = index
    },
  },
};
</script>

<style lang="less" scoped></style>
