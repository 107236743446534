<template>
  <div class="my_box">
      <div class="my_header">
        <img :src="userInfo.avatar" alt="">
        <span>{{userInfo.nickName}}</span>
      </div>
      <div class="mynum">
         <div class="shou" @click="goCollect(1)">
            <div class="text">收藏</div>
            <div class="num">{{userInfo.collectNumber}} <img src="../../../../src/assets/image/right.png" /></div>
         </div>
         <div class="liu" @click="goCollect(2)">
            <div class="text">浏览</div>
            <div class="num">{{userInfo.browseNumber}} <img src="../../../../src/assets/image/right.png" /></div>
         </div>
      </div>
      <!-- 我的订单 -->
      <div class="my_order">
        <div class="order_title">
            <div class="title">我的订单</div>
            <div class="more" @click="goOrderList">全部订单 <img src="../../../../src/assets/image/right.png" /></div>
        </div>
        <div class="list">
            <div class="tab_nav" v-for="(item, index) in icon" :key="index" @click="golist(item.type)">
                <div class="tab_img">
                    <img :src="item.icon" alt="">
                </div>
                <div class="tab_text">{{item.text}}</div>
            </div>
        </div>
      </div>

      <div class="order_cell">
        <van-cell is-link v-for="(item, index) in cellList" :key="index" :url="item.url">
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #title>
                <img class="list_img" :src="item.icon" alt="">
                <span class="custom-title">{{item.text}}</span>
            </template>
        </van-cell>
        <van-cell is-link @click="openRule(3)">
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #title>
                <img class="list_img" src="../../../../src/assets/image/list3.png" alt="">
                <span class="custom-title">售后退款物流政策</span>
            </template>
        </van-cell>
        <van-cell is-link @click="openRule(4)">
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #title>
                <img class="list_img" src="../../../../src/assets/image/list3.png" alt="">
                <span class="custom-title">用户协议</span>
            </template>
        </van-cell>
        <van-cell is-link @click="openRule(5)">
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #title>
                <img class="list_img" src="../../../../src/assets/image/list3.png" alt="">
                <span class="custom-title">隐私保护指引</span>
            </template>
        </van-cell>
      </div>
      <div class="quit" @click="loginOut">
         退出登录
      </div>
    <bottomList></bottomList>
    <div class="menu">
      <menuBottom></menuBottom>
    </div>
  </div>
</template>

<script>
import menuBottom from '@/views/components/business/menuBottom'
import { getToken } from '@/utils/auth'
import { getInfo, getRule } from '@/api/user'
export default {
  name: "myindex",
  components: {
    menuBottom
  },
  data() {
    return {
        userInfo: {},
        icon: [
            {
                icon: require('../../../../src/assets/image/order1.png'),
                text: '待付款',
                type: 1
            },{
                icon: require('../../../../src/assets/image/order2.png'),
                text: '待发货',
                type: 2
            },{
                icon: require('../../../../src/assets/image/order3.png'),
                text: '待收货',
                type: 3
            },{
                icon: require('../../../../src/assets/image/order4.png'),
                text: '退换货',
                type: 5
            }
        ],
        cellList: [
            {
                icon: require('../../../../src/assets/image/list1.png'),
                text: '收货地址',
                url: '../order/address'
            },{
                icon: require('../../../../src/assets/image/list2.png'),
                text: '我的客服',
                url: 'https://chatbot.aliyuncs.com/intl/index.htm?locale=zh-CN&from=blD4dOkH24'
            }
            // {
            //     icon: require('../../../../src/assets/image/list3.png'),
            //     text: '帮助中心'
            // },{
            //     icon: require('../../../../src/assets/image/list4.png'),
            //     text: '设置'
            // }
        ]
    };
  },

  mounted() {
    if (!getToken()) {
        // 去登录
        this.$router.push('../login/index')
    } else {
        this.getUserInfo()
    }
  },

  methods: {
    // 查看 协议
    openRule(id) {
        this.$router.push({
            name: `openDeal`,
            params: {
            id: id
            }
        })
    },
    // 获取用户信息
    getUserInfo() {
        getInfo().then(res => {
            this.userInfo = res.data
        })
    },
    goOrderList() {
        this.$router.push('../order/list')
    },
    golist(type){
        if (type != 5) {
            this.$router.push({
                name: `orderlist`,
                params: {
                type: type
                }
            })
        } else {
            this.$router.push({
                name: `aftermarketList`,
                params: {
                type: 1
                }
            })
        }
    },
    goCollect(id) {
        this.$router.push({
            name: `collectlist`,
            params: {
            id: id
            }
        })
    },
    // 退出登录
    loginOut() {
        this.$dialog.confirm({
            title: '退出登录',
            message: '您确定要退出登录吗？',
        })
        .then(() => {
            sessionStorage.removeItem('educationtis-Token')
            this.$router.push('../login/index')
        })
        .catch(() => {
            // on cancel
        });
    },
  },
};
</script>

<style lang="less" scoped>
.menu{
    width: 100vw;
    height: 66rem;
}
.my_box{
    height: 100vh;
    background: #F6F7FA;
    padding:  0rem 11rem;
    .my_header{
        padding-top: 63rem;
        text-align: left;
        img{
            width: 54rem;
            height: 54rem;
            border-radius: 50%;
            vertical-align: middle;
        }
        span{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 18rem;
            color: #000000;
            margin-left: 10rem;
        }
    }
    .mynum{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 52rem;
        background: #fff;
        margin-top: 20rem;
        border-radius: 4rem;
        .shou{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            div{
                flex: 1;
            }
            .num{
                border-right: 2rem solid #F7F7F8;
            }
        }
        .liu{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            div{
                flex: 1;
            }
        }
        .text{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 14rem;
            color: #333333;
            margin-left: 16rem;
        }
        .num{
            font-family: DINPro, DINPro;
            font-weight: 500;
            font-size: 14rem;
            color: #B7BAC5;
            text-align: center;
        }
    }
}

.my_order{
    background: #fff;
    margin-top: 10rem;
    padding: 14rem 17rem;
    border-radius: 4rem;
    .order_title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 15rem;
            color: #000000;
        }
        .more{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 13rem;
            color: #ADB1BC;
            img {
                height: 9rem;
            }
        }
    }
    .list{
        margin: 10rem 0rem;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
    .tab_nav{
        width: 25%;
        text-align: center;
        margin-top: 4rem;
        img{
            width: 38rem; 
        }
        .tab_text{
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12rem;
            color: #333333;
            line-height: 17rem;
            font-style: normal;
        }
    }
}
}
.order_cell{
    margin-top: 10rem;
    border-radius: 4rem;
    overflow: hidden;
    .list_img{
        height: 22rem;
        vertical-align: middle;
        margin-right: 7rem;
    }
    .custom-title{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 15rem;
        color: #5C666C;
        vertical-align: middle;
        line-height: 22rem;
    }
}
.quit{
    height: 40rem;
    // background: red;
    color: #5C666C;
    border: 1rem solid #dedede;
    line-height: 40rem;
    text-align: center;
    font-size: 16rem;
    border-radius: 4rem;
    margin-top: 20rem;
}
</style>
